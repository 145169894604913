import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgSettings } from "@ollie-sports/models";
import { firestoreLiftDocSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgSecret__client__getOrgSecretSubscription(p: { orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftDocSubToBifrostSub(h.OrgSecret.docSubscription(p.orgId));
}

// i18n certified - complete

import { AccountId, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";

export async function orgSecret__orgServer__getOrgMerchantDetails(p: { orgId: OrgId; selfAccountId: AccountId }) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [org, orgSecret] = await Promise.all([h.Org.getDoc(p.orgId), h.OrgSecret.getDoc(p.orgId)]);

  if (!org || !org.accounts[p.selfAccountId]?.exists) {
    throw new Error(`User can't access org merchant data: ${p.selfAccountId} ${p.orgId}`);
  }

  // Payment TODO: Implement this

  return null;

  // SERVER_ONLY_TOGGLE
}

orgSecret__orgServer__getOrgMerchantDetails.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};
